<template>
  <div class="container">
    <!-- <el-tabs v-model="activeName" @tab-click="handleClick"> -->
    <el-tabs v-model="activeName">
      <el-tab-pane label="文章管理" name="first">
        <el-form
          :inline="true"
          :model="formInline"
          ref="formInline"
          label-position="right"
          class="demo-form-inline"
          label-width="90px"
        >
          <el-form-item label="文章状态：" prop="articleStatus">
            <el-select
              v-model="formInline.articleStatus"
              placeholder="请选择"
              clearable
            >
              <el-option
                v-for="(item, index) in articleStatusList"
                :key="index"
                :label="
                  item == 0 ? '申请发布' : item == 1 ? '申请删除' : '已发布'
                "
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="文章分类：" prop="categoryId">
            <el-select
              v-model="formInline.categoryId"
              placeholder="请选择"
              clearable
            >
              <el-option
                v-for="item in categoryList"
                :key="item.category_id"
                :label="item.category_name"
                :value="item.category_id"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item class="button_left">
            <el-button type="primary" @click="onSubmit(1)" icon="el-icon-search"
              >查询</el-button
            >
            <el-button
              class="button_reset"
              @click="resetForm('formInline')"
              plain
              icon="el-icon-refresh"
              >重置</el-button
            >
          </el-form-item>
          <el-form-item class="button_right">
            <el-button
              @click="
                drawer = true;
                drawerTitle = '添加文章';
                content = '<p></p>';
              "
              type="primary"
              icon="el-icon-circle-plus-outline"
            >
              添加文章
            </el-button>
          </el-form-item>
        </el-form>

        <el-table
          ref="multipleTable"
          :data="formattedArticleList"
          tooltip-effect="dark"
          style="width: 100%"
          stripe
          :height="height"
          size="mini"
        >
          <el-table-column type="index" width="60" label="序号" fixed>
          </el-table-column>
          <el-table-column prop="title" label="标题" min-width="120">
          </el-table-column>
          <!-- <el-table-column prop="introduction" label="简介" min-width="200"> </el-table-column> -->
          <el-table-column prop="category_name" label="分类" min-width="120">
          </el-table-column>
          <el-table-column prop="article_status" label="状态" min-width="120">
            <template slot-scope="scope">
              <span v-if="scope.row.article_status == 0">申请发布</span>
              <span v-else-if="scope.row.article_status == 1">申请删除</span>
              <span v-else>已发布</span>
            </template>
          </el-table-column>

          <el-table-column
            prop="pub_date"
            label="发布日期"
            :width="tabelColWidth"
          >
          </el-table-column>
          <el-table-column
            prop="update_date"
            label="更新日期"
            :width="tabelColWidth"
          >
          </el-table-column>
          <el-table-column
            prop="article_create_time"
            label="创建时间"
            :width="tabelColWidth"
          >
          </el-table-column>

          <el-table-column label="操作" fixed="right" width="200">
            <template slot-scope="scope">
              <el-popconfirm
                confirm-button-text="确定"
                cancel-button-text="取消"
                cancel-button-type="Primary"
                icon="el-icon-info"
                icon-color="red"
                title="确定发布吗？"
                v-show="
                  scope.row.article_status == 0 && myUserInfo.user_id == 1
                "
                @confirm="handlePass(scope.$index, scope.row)"
              >
                <el-button slot="reference" type="text" class="red">
                  发布
                </el-button>
              </el-popconfirm>
              <el-popconfirm
                confirm-button-text="确定"
                cancel-button-text="取消"
                cancel-button-type="Primary"
                icon="el-icon-info"
                icon-color="red"
                title="确定取消发布吗？"
                v-show="
                  scope.row.article_status == 2 && myUserInfo.user_id == 1
                "
                @confirm="handlePass(scope.$index, scope.row)"
              >
                <el-button slot="reference" type="text"> 取消发布 </el-button>
              </el-popconfirm>

              <el-button
                type="text"
                @click="
                  handleEdit(scope.$index, scope.row);
                  drawerTitle = '编辑';
                "
              >
                编辑
              </el-button>

              <el-popconfirm
                confirm-button-text="确定"
                cancel-button-text="取消"
                cancel-button-type="Primary"
                icon="el-icon-info"
                icon-color="red"
                title="确定删除吗？"
                v-show="
                  (myUserInfo.user_id == 1 && scope.row.article_status == 0) ||
                  (scope.row.author_id != 1 &&
                    myUserInfo.role_id != 1 &&
                    scope.row.article_status == 0)
                "
                @confirm="handleDelete(scope.$index, scope.row)"
              >
                <el-button type="text" slot="reference" class="red">
                  删除
                </el-button>
              </el-popconfirm>
              <el-popconfirm
                confirm-button-text="确定"
                cancel-button-text="取消"
                cancel-button-type="Primary"
                icon="el-icon-info"
                icon-color="red"
                title="确定取消删除申请吗?"
                v-show="
                  (myUserInfo.user_id == 1 && scope.row.article_status == 1) ||
                  (scope.row.author_id != 1 &&
                    myUserInfo.role_id != 1 &&
                    scope.row.article_status == 1)
                "
                @confirm="handleRecover(scope.$index, scope.row)"
              >
                <el-button type="text" slot="reference">
                  取消删除申请
                </el-button>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>

        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :page-sizes="[10, 25, 50, 100]"
          :page-size="10"
          layout="total, sizes, prev, pager, next"
          :total="articleTotal"
        >
        </el-pagination>

        <el-drawer
          :title="drawerTitle"
          :visible.sync="drawer"
          :with-header="true"
          :before-close="handleClose"
          size="39%"
          :destroy-on-close="true"
        >
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="120px"
            v-show="drawerTitle == '添加文章'"
          >
            <el-form-item label="标题" prop="title">
              <el-input
                v-model="ruleForm.title"
                placeholder="请填写标题"
              ></el-input>
            </el-form-item>
            <!-- <el-form-item label="作者" prop="authorName">
              <el-input
                v-model="ruleForm.authorName"
                placeholder="请填写作者"
              ></el-input>
            </el-form-item> -->
            <el-form-item label="类别" prop="categoryId">
              <el-select
                v-model="ruleForm.categoryId"
                placeholder="请选择"
                clearable
              >
                <el-option
                  v-for="item in categoryList"
                  :key="item.category_id"
                  :label="item.category_name"
                  :value="item.category_id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-show="!disabled" label="简介" prop="introduction">
              <el-input
                type="textarea"
                v-model="ruleForm.introduction"
                placeholder="请填写简介"
                :disabled="disabled"
              ></el-input>
            </el-form-item>
            <el-form-item label="封面" prop="avatar">
              <div class="avatar_box" style="text-align: center">
                <img width="120px" v-if="avatarURL" :src="avatarURL" />
                <el-button type="text" @click="editAvatar">修改封面</el-button>
              </div>
            </el-form-item>
            <el-form-item label="内容" prop="content">
              <WangEditor
                :content="content"
                @toParent="getContent"
                class="zindex_max"
              />
            </el-form-item>
            <el-form-item class="right">
              <el-button @click="cancel" plain>取消</el-button>
              <el-button type="primary" @click="confirm('ruleForm')"
                >确认</el-button
              >
            </el-form-item>
          </el-form>
          <el-form
            :model="ruleForm2"
            :rules="rules"
            ref="ruleForm2"
            label-width="120px"
            v-show="drawerTitle == '编辑'"
          >
            <el-form-item label="标题" prop="title2">
              <el-input
                v-model="ruleForm2.title2"
                placeholder="请填写标题"
              ></el-input>
            </el-form-item>
            <!-- <el-form-item label="作者" prop="authorName2">
              <el-input
                v-model="ruleForm2.authorName2"
                placeholder="请填写作者"
              ></el-input>
            </el-form-item> -->
            <el-form-item label="类别" prop="categoryId2">
              <el-select
                v-model="ruleForm2.categoryId2"
                placeholder="请选择"
                clearable
              >
                <el-option
                  v-for="item in categoryList"
                  :key="item.category_id"
                  :label="item.category_name"
                  :value="item.category_id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-show="!disabled" label="简介" prop="introduction2">
              <el-input
                type="textarea"
                v-model="ruleForm2.introduction2"
                placeholder="请填写简介"
                :disabled="disabled"
              ></el-input>
            </el-form-item>
            <el-form-item label="封面" prop="avatar2">
              <div class="avatar_box" style="text-align: center">
                <img width="120px" v-if="avatarURL" :src="avatarURL" />
                <el-button type="text" @click="editAvatar">修改封面</el-button>
              </div>
            </el-form-item>
            <el-form-item label="内容" prop="content2">
              <WangEditor
                :content="content"
                @toParent="getContent"
                class="zindex_max"
              />
            </el-form-item>

            <el-form-item class="right">
              <el-button @click="cancel" plain>取消</el-button>
              <el-button type="primary" @click="confirm('ruleForm2')"
                >确认</el-button
              >
            </el-form-item>
          </el-form>
        </el-drawer>

        <!-- 修改封面 -->
        <el-dialog title="修改封面" :visible.sync="editAvatarDialog">
          <el-row type="flex" justify="center">
            <div class="cropper">
              <vueCropper
                ref="cropper"
                :img="option.img"
                :outputSize="option.size"
                :outputType="option.outputType"
                :info="option.info"
                :full="option.full"
                :canMove="option.canMove"
                :canMoveBox="option.canMoveBox"
                :original="option.original"
                :autoCrop="option.autoCrop"
                :autoCropWidth="option.autoCropWidth"
                :autoCropHeight="option.autoCropHeight"
                :fixedBox="option.fixedBox"
                @realTime="realTime"
              ></vueCropper>
            </div>
            <div class="previewBox">
              <div :style="previews.div" class="preview">
                <img :src="previews.url" :style="previews.img" />
              </div>
              <el-row type="flex" justify="center">
                <el-upload
                  action=""
                  :show-file-list="false"
                  :auto-upload="false"
                  :on-change="uploadImg"
                >
                  <el-button size="mini" type="primary"> 选择封面 </el-button>
                </el-upload>
              </el-row>
              <br />
              <el-row>
                <el-button
                  icon="el-icon-plus"
                  circle
                  size="mini"
                  @click="changeScale(1)"
                ></el-button>
                <el-button
                  icon="el-icon-minus"
                  circle
                  size="mini"
                  @click="changeScale(-1)"
                ></el-button>
                <el-button
                  icon="el-icon-download"
                  circle
                  size="mini"
                  @click="down('blob')"
                ></el-button>
                <el-button
                  icon="el-icon-refresh-left"
                  circle
                  size="mini"
                  @click="rotateLeft"
                ></el-button>
                <el-button
                  icon="el-icon-refresh-right"
                  circle
                  size="mini"
                  @click="rotateRight"
                ></el-button>
              </el-row>
            </div>
          </el-row>
          <span slot="footer" class="dialog-footer">
            <el-button @click="editAvatarDialog = false">取 消</el-button>
            <el-button type="primary" @click="saveEditAvatar">确 定</el-button>
          </span>
        </el-dialog>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { VueCropper } from "vue-cropper";
import { mapActions, mapGetters, mapState } from "vuex";
import WangEditor from "../../../components/common/WangEditor";
export default {
  name: "ArticlesManage",
  components: { VueCropper, WangEditor },
  data() {
    return {
      disabled: false,
      dialogFormVisible: false,
      dialogFormVisible2: false,
      editAvatarDialog: false,
      height: 740,
      tabelColWidth: 230,
      currentPage: 1,
      drawerTitle: "",
      content: "",
      drawer: false,
      activeName: "first",
      // userIds: [],
      articleStatusList: [0, 1, 2],
      formData: new FormData(),
      formData2: new FormData(),
      multipleSelection: [],
      previews: {},
      avatarURL: null,
      option: {
        img: "", // 裁剪图片的地址
        info: true, // 裁剪框的大小信息
        outputSize: 1, // 剪切后的图片质量（0.1-1）
        full: true, // 输出原图比例截图 props名full
        outputType: "png", // 裁剪生成额图片的格式
        canMove: true, // 能否拖动图片
        original: false, // 上传图片是否显示原始宽高
        canMoveBox: false, // 能否拖动截图框
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: 355, // 默认生成截图框宽度
        autoCropHeight: 200, // 默认生成截图框高度
        fixedBox: false, // 截图框固定大小
        centerBox: false, // 截图框是否被限制在图片里面
        infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
      },
      formInline: {
        currentPage: 1,
        pageSize: 10,
        articleStatus: null,
        categoryId: null,
      },
      ruleForm: {
        title: null,
        introduction: null,
        categoryId: null,
        content: null,
        // authorName: '',
        avatar: null,
      },
      ruleForm2: {
        title2: null,
        introduction2: null,
        categoryId2: null,
        content2: null,
        // authorName2: '',
        avatar2: null,
        articleId: null,
      },
      rules: {
        avatar: [{ required: true, message: "请选择封面", trigger: "blur" }],
        title: [
          { required: true, message: "请输入标题", trigger: "blur" },
          {
            min: 1,
            max: 10,
            message: "长度在 1 到 10 个字符",
            trigger: ["blur", "change"],
          },
        ],
        title2: [
          { required: true, message: "请输入标题", trigger: "blur" },
          {
            min: 1,
            max: 10,
            message: "长度在 1 到 10 个字符",
            trigger: ["blur", "change"],
          },
        ],
        // authorName: [
        //   { required: true, message: "请输入作者", trigger: "blur" },
        //   {
        //     min: 1,
        //     max: 10,
        //     message: "长度在 1 到 20 个字符",
        //     trigger: ["blur", "change"],
        //   },
        // ],
        // authorName2: [
        //   { required: true, message: "请输入作者", trigger: "blur" },
        //   {
        //     min: 1,
        //     max: 10,
        //     message: "长度在 1 到 20 个字符",
        //     trigger: ["blur", "change"],
        //   },
        // ],
        introduction: [
          { required: true, message: "请输入简介", trigger: "blur" },
          {
            min: 1,
            max: 50,
            message: "长度在 1 到 50 个字符",
            trigger: ["blur", "change"],
          },
        ],
        introduction2: [
          { required: true, message: "请输入简介", trigger: "blur" },
          {
            min: 1,
            max: 50,
            message: "长度在 1 到 50 个字符",
            trigger: ["blur", "change"],
          },
        ],
        categoryId: [
          { required: true, message: "请选择分类", trigger: "blur" },
        ],
        categoryId2: [
          { required: true, message: "请选择分类", trigger: "blur" },
        ],
        content: [{ required: true, message: "输入内容", trigger: "blur" }],
        content2: [{ required: true, message: "输入内容", trigger: "blur" }],
      },
    };
  },
  computed: {
    ...mapState("article", [
      "articleList",
      "status",
      "articleTotal",
      "message",
      "status",
    ]),
    ...mapState("category", ["categoryList"]),
    ...mapState("user", ["myUserInfo"]),
    ...mapGetters("article", ["formattedArticleList"])
  },
  methods: {
    ...mapActions("article", {
      getArticleB: "getArticleB",
      addArticle: "addArticle",
      updateArticle: "updateArticle",
      passArticle: "passArticle",
      deleteArticle: "deleteArticle",
    }),
    ...mapActions("category", {
      getCategory: "getCategory",
    }),
    handlePass(index, row) {
      let articleId = row.article_id;
      let articleStatus = row.article_status == 0 ? 2 : 0;
      this.passArticle({ articleStatus, articleId }).then(() => {
        if (this.status == 200) {
          this.$message({
            showClose: true,
            type: "success",
            message: this.message,
          });
          this.onSubmit();
        } else {
          this.$message({
            showClose: true,
            type: "error",
            message: this.message,
          });
        }
      });
    },
    cancel() {
      this.handleClose();
    },
    getContent(content) {
      this.ruleForm.content = content;
      this.ruleForm2.content2 = content;
    },
    handleSizeChange(val) {
      this.formInline.pageSize = val;
      // console.log(`每页 ${val} 条`);
      this.onSubmit();
    },
    handleCurrentChange(val) {
      this.formInline.currentPage = val;
      // console.log(`当前页: ${val}`);
      this.onSubmit();
    },
    // handleClick(tab, event) {
    //   console.log(tab, event);
    // },
    // 查询列表
    onSubmit(nowPage) {
      let currentPage
      if (nowPage) {
        this.currentPage = nowPage;
        currentPage = nowPage;
        this.formInline.currentPage = nowPage;
      }
      currentPage = this.formInline.currentPage;
      let pageSize = this.formInline.pageSize;
      let articleStatus =
        this.formInline.articleStatus || this.formInline.articleStatus === 0
          ? this.formInline.articleStatus
          : -1;
      let categoryId = this.formInline.categoryId || 0;
      if (articleStatus == -1 && categoryId == 0) {
        // console.log("查询全部!", articleStatus,',', categoryId);
        this.getArticleB({
          currentPage,
          pageSize,
          articleId: 0,
          articleStatus,
          categoryId,
        });
      } else {
        // console.log(this.formInline);
        this.getArticleB({
          articleId: 0,
          currentPage,
          pageSize,
          articleStatus,
          categoryId,
        });
      }
      // console.log("formattedArticleList", this.formattedArticleList);
    },
    resetForm(formName) {
      this.formData = null;
      this.formData2 = null;
      this.formData = new FormData();
      this.formData2 = new FormData();
      this.avatarURL = null;
      this.content = "<p></p>";
      this.$refs[formName].resetFields();
    },
    // 删除
    handleDelete(index, row) {
      let articleId = row.article_id;
      if (articleId) {
        this.deleteArticle({ articleId }).then(() => {
          if (this.status == 200) {
            this.$message({
              showClose: true,
              type: "success",
              message: this.message,
            });
            this.onSubmit();
          } else {
            this.$message({
              showClose: true,
              type: "error",
              message: this.message,
            });
          }
        });
      }
    },
    handleRecover(index, row) {
      let articleId = row.article_id;
      let articleStatus = 0;
      if (articleId) {
        this.passArticle({ articleId, articleStatus }).then(() => {
          if (this.status == 200) {
            this.$message({
              showClose: true,
              type: "success",
              message: this.message,
            });
            this.onSubmit();
          } else {
            this.$message({
              showClose: true,
              type: "error",
              message: this.message,
            });
          }
        });
      }
    },
    // 编辑
    handleEdit(index, row) {
      this.ruleForm2.title2 = row.title;
      this.ruleForm2.introduction2 = row.introduction;
      this.ruleForm2.categoryId2 = row.category_id;
      this.ruleForm2.avatar2 = row.cover_img;
      this.ruleForm2.content2 = row.content;
      this.ruleForm2.authorName2 = row.author_name;
      this.ruleForm2.articleId = row.article_id;
      this.avatarURL = row.cover_img;
      // console.log(this.ruleForm2);
      // console.log(row);
      this.content = row.content;
      this.drawer = true;
      if (this.file instanceof File) {
        this.imageUrl = URL.createObjectURL(this.file.raw);
      } else {
        this.imageUrl = row.image;
      }
    },
    handleClose(done) {
      this.$confirm("确认关闭？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (this.drawer) {
            this.resetForm("ruleForm");
            this.resetForm("ruleForm2");
            this.drawer = false;
          }
          if (this.dialogFormVisible) {
            this.resetForm("form");
            this.dialogFormVisible = false;
          }
          if (this.dialogFormVisible2) {
            this.resetForm("form2");
            this.dialogFormVisible2 = false;
          }
          done();
        })
        .catch(() => {});
    },
    confirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.drawerTitle === "添加文章") {
            // console.log(this.ruleForm);
            // 调用添加文章接口方法
            this.formData.append("title", this.ruleForm.title);
            this.formData.append("authorName", this.ruleForm.authorName);
            this.formData.append("categoryId", this.ruleForm.categoryId);
            this.formData.append("introduction", this.ruleForm.introduction);
            this.formData.append("content", this.ruleForm.content);
            this.addArticle(this.formData).then(() => {
              if (this.status == 200) {
                this.$message({
                  showClose: true,
                  type: "success",
                  message: this.message,
                });
                this.onSubmit();
                this.resetForm(formName);
                this.drawer = false;
              } else {
                this.resetForm(formName);
                this.$message({
                  showClose: true,
                  type: "error",
                  message: this.message,
                });
              }
            });
          }
          if (this.drawerTitle === "编辑") {
            // console.log(this.ruleForm2);
            // 调用编辑接口方法
            this.formData2.append("articleId", this.ruleForm2.articleId);
            this.formData2.append("title", this.ruleForm2.title2);
            this.formData2.append("authorName", this.ruleForm2.authorName2);
            this.formData2.append("categoryId", this.ruleForm2.categoryId2);
            this.formData2.append("introduction", this.ruleForm2.introduction2);
            this.formData2.append("content", this.ruleForm2.content2);
            this.updateArticle(this.formData2).then(() => {
              if (this.status == 200) {
                this.$message({
                  showClose: true,
                  type: "success",
                  message: this.message,
                });
                this.onSubmit();
                this.resetForm(formName);
                this.drawer = false;
              } else {
                this.resetForm(formName);
                this.$message({
                  showClose: true,
                  type: "error",
                  message: this.message,
                });
              }
            });
          }
        } else {
          this.$message({
            showClose: true,
            type: "warning",
            message: "请先填写好表单!",
          });
          return false;
        }
      });
      // console.log(this.ruleForm2.avatar2);
    },
    // 裁剪封面相关函数
    editAvatar() {
      this.editAvatarDialog = true;
      this.option.img = this.avatarURL;
    },
    // 保存封面修改
    saveEditAvatar() {
      this.editAvatarDialog = false;
      this.finish("blob");
    },
    // 放大/缩小
    changeScale(num) {
      num = num || 1;
      this.$refs.cropper.changeScale(num);
    },
    // 左旋转
    rotateLeft() {
      this.$refs.cropper.rotateLeft();
    },
    // 右旋转
    rotateRight() {
      this.$refs.cropper.rotateRight();
    },
    // 保存上传图片
    finish(type) {
      if (type === "blob") {
        this.$refs.cropper.getCropBlob((data) => {
          if (this.drawerTitle == "编辑") {
            this.ruleForm2.avatar2 = data;
            // console.log(this.ruleForm2.avatar2);
          }
          if (this.drawerTitle == "添加文章") {
            this.ruleForm.avatar = data;
            // console.log(this.ruleForm.avatar);
          }
          if (data.type === "image/png") {
            this.filename = "coverImg.png";
          }
          if (data.type === "image/jpeg") {
            this.filename = "coverImg.jpeg";
          }
          if (data.type === "image/jpg") {
            this.filename = "coverImg.jpeg";
          }
          if (data.type === "image/gif") {
            this.filename = "coverImg.gif";
          }
          if (data.type === "image/webp") {
            this.filename = "coverImg.webp";
          }
          if (data) {
            // console.log("添加了图片参数");
            if (this.drawerTitle == "添加文章") {
              this.formData.append(
                "coverImg",
                this.ruleForm.avatar,
                this.filename
              );
              this.avatarURL = window.URL.createObjectURL(this.ruleForm.avatar);
            }
            if (this.drawerTitle == "编辑") {
              this.formData2.append(
                "coverImg",
                this.ruleForm2.avatar2,
                this.filename
              );
              this.avatarURL = window.URL.createObjectURL(
                this.ruleForm2.avatar2
              );
            }
          }
        });
      } else {
        // this.$refs.cropper.getCropData((data) => {
        //   return data;
        // });
      }
    },
    // 实时预览函数
    realTime(data) {
      this.previews = data;
    },
    // 下载图片
    down(type) {
      var aLink = document.createElement("a");
      aLink.download = "author-img";
      if (type === "blob") {
        this.$refs.cropper.getCropBlob((data) => {
          aLink.href = window.URL.createObjectURL(data);
          aLink.click();
        });
      } else {
        this.$refs.cropper.getCropData((data) => {
          aLink.href = data;
          aLink.click();
        });
      }
    },
    // 更换封面--上传本地图片
    uploadImg(file) {
      this.filename = file.name;
      var _this = this;
      var reader = new FileReader();
      reader.onload = (e) => {
        let data;
        if (typeof e.target.result === "object") {
          data = window.URL.createObjectURL(new Blob([e.target.result]));
        } else {
          data = e.target.result;
        }
        _this.option.img = data;
      };
      reader.readAsArrayBuffer(file.raw);
    },
  },
  mounted() {
    this.getCategory({ categoryStatus: 2 });
    this.onSubmit();
    this.$nextTick(function () {
      this.height =
        window.innerHeight - this.$refs.multipleTable.$el.offsetTop - 220;
      // 监听窗口大小变化
      let self = this;
      window.onresize = function () {
        if (self.$refs.multipleTable) {
          self.height =
            window.innerHeight - self.$refs.multipleTable.$el.offsetTop - 220;
        }
      };
    });
  },
};
</script>

<style lang="less" scoped>
@color: #409eff;
.right {
  float: right;
}
.red {
  color: #f56c6c;
}
// 裁剪
.avatar_box {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.previewBox {
  text-align: center;
  margin-left: 60px;
}

.preview {
  // 裁剪预览图
  width: 500px;
  height: 400px;
  margin: 0px auto 20px auto;
  // border-radius: 50%;
  border: 1px solid #ccc;
  background-color: #ccc;
  overflow: hidden;
}

.cropper {
  // 裁剪盒子
  width: 500px;
  height: 400px;
}

.container {
  .el-tabs {
    /deep/ #tab-first {
      font-weight: 550;
      font-size: 1.25rem;
    }
    .demo-form-inline {
      /deep/ .el-input__inner {
        width: 14.375rem;
      }
      /deep/ .el-form-item__label {
        padding: 0;
        font-size: 18px;
      }
      .button_right {
        float: right;
        .el-button {
          margin-left: 0.625rem;
        }
      }
    }

    /deep/ .el-table {
      font-size: 16px;
      .el-button {
        font-size: 16px;
        margin: 0 10px 0 0;
      }
      .is_show {
        display: block;
      }
      .el-table__fixed-right::before,
      .el-table__fixed::before {
        height: 0;
      }
      &::before {
        display: none;
      }
    }
    /deep/ .el-dialog {
      .el-dialog__header {
        &:after {
          width: 95%;
          height: 1px;
          background-image: linear-gradient(
            to right,
            #ccc 0%,
            #ccc 50%,
            transparent 50%
          );
          background-size: 10px 1px;
          background-repeat: repeat-x;
          position: absolute;
          top: 3.5em;
          left: 1em;
          z-index: 1;
          content: "";
        }
      }
      .el-form-item__label {
        font-size: 18px;
      }
    }
    /deep/ .el-drawer__header {
      font-size: 20px;
      color: #333333;
      overflow: hidden;
      &:after {
        width: 93%;
        height: calc(100% - 0.1rem);
        background-image: linear-gradient(
          to right,
          #ccc 0%,
          #ccc 50%,
          transparent 50%
        );
        background-size: 10px 1px;
        background-repeat: repeat-x;
        position: absolute;
        top: 3em;
        content: "";
      }
    }
    /deep/ .el-drawer__body {
      padding: 10px 24px;
      margin-bottom: 20px;
      .el-form-item__label {
        font-size: 18px;
      }
      .zindex_max {
        z-index: 100000;
      }
      .el-select {
        width: 100%;
      }
    }

    .avatar-uploader-icon {
      color: #8c939d;
      width: 104px;
      height: 104px;
      line-height: 104px;
      text-align: center;
      border: 1px dashed #ccc;
      border-radius: 5px;
      &:hover {
        border-color: @color;
      }
    }
    .avatar {
      width: 104px;
      height: 104px;
      display: block;
      border-radius: 5px;
    }
  }
  .el-pagination {
    float: right;
    position: relative;
    margin: 10px 0;
    padding: 0;
    /deep/ .el-pager .active {
      background-color: @color !important;
    }
  }
}
</style>
