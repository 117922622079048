<template>
  <div style="border: 1px solid #ccc">
    <Toolbar
      style="border-bottom: 1px solid #ccc"
      :editor="editor"
      :defaultConfig="toolbarConfig"
      :mode="mode"
    />
    <Editor
      style="min-height: 400px; height: 100%; overflow-y: hidden"
      v-model="html"
      :defaultConfig="editorConfig"
      :mode="mode"
      @onCreated="onCreated"
      @onChange="getContent"
    />
  </div>
</template>

<script>
import Vue from "vue";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import { reqUpload } from "../../api/index";
export default Vue.extend({
  name: "WangEditor",
  components: { Editor, Toolbar },
  props: ["content"],
  data() {
    return {
      editor: null,
      formData: new FormData(),
      html: "",
      toolbarConfig: {
        // toolbarKeys: [ /* 显示哪些菜单，如何排序、分组 */ ],
        // excludeKeys: [ /* 隐藏哪些菜单 */ ],
        // excludeKeys: ["headerSelect", "italic", "group-more-style"],
        // toolbarKeys: [],
        excludeKeys: ["uploadVideo"],
      },
      editorConfig: {
        placeholder: "请输入内容...",
        // 所有的菜单配置，都要在 MENU_CONF 属性下
        MENU_CONF: {
          // 配置上传图片
          uploadImage: {
            customUpload: this.update,
          },
        },
      },
      mode: "default",
    };
  },
  methods: {
    update(file, insertFn) {
      // file 即选中的文件
      // 自己实现上传，并得到图片 url alt href
      // let name = file.name;
      // let suffix = file.type.split("/")[1];
      // name = name + "." + suffix;
      this.formData.append("img", file, file.name);
      reqUpload(this.formData).then((res) => {
        if (res.status == 200) {
          // console.log(res.data.url);
          // 最后插入图片
          insertFn(res.data.url, file.name, res.data.url);
          // console.log();
          this.formData = null;
          this.formData = new FormData();
        } else {
          this.formData = null;
          this.formData = new FormData();
          this.$message({
            type: "error",
            message: "上传失败!",
          });
        }
      });
    },
    getContent() {
      // console.log(this.html);
      if (this.content) {
        this.$emit("toParent", this.html);
      }
      // else {
      //   // this.$emit("toParent", this.html);
      // }
    },
    onCreated(editor) {
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
      // const toolbar = DomEditor.getToolbar(editor);
      // console.log("工具栏配置", editor.getConfig()); // 工具栏配置
      // console.log("编辑器", editor); // 工具栏配置
      // console.log("图片配置", this.editorConfig); // 工具栏配置
    },
  },
  mounted() {
    // 模拟 ajax 请求，异步渲染编辑器
    setTimeout(() => {
      if (this.content) {
        // this.html = "<p>" + this.content + "</p>";
        this.html = this.content;
      } else {
        this.html = "";
      }
    });
  },
  beforeDestroy() {
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁编辑器
  },
});
</script>

<style src="@wangeditor/editor/dist/css/style.css">
</style>